.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

.background-overlay-gradient {
	display: grid;
	place-content: center;
	place-items: center;
	// background: linear-gradient(transparent, #151719), url("https://i.redd.it/85g2ha0dmqv51.jpg") no-repeat center center / cover;
	// background: linear-gradient(transparent, #151719), url("https://www.hedayatmusic.com/hs-fs/hubfs/Photo%20pour%20web/_ES_5134-(1).jpg?width=900&name=_ES_5134-(1).jpg") no-repeat center center / cover;
	// background: linear-gradient(transparent, #151719), url("http://www.big3studios.com/wp-content/uploads/2011/04/bga1.jpg") no-repeat center center / cover;
	background: linear-gradient(transparent, #151719), url("./../../../images/microphone.jpg") no-repeat center center / cover;
	height: 100vh;
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
